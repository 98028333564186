import React from 'react'
import Layout from '../components/layout'
import Header from '../components/header'
import SEO from '../components/seo'
import Container from '../components/mainContent/container'
import Estilos from '../components/mainContent/container.module.css'
import SlideShowAplicaciones from '../components/imageComponents/slideShowAplicaciones'
import ImagenAplicaciones from '../components/imageComponents/imagenAplicaciones'
import ImagenAplicaciones2 from '../components/imageComponents/imagenAplicaciones2'
import ImagenAplicaciones3 from '../components/imageComponents/imagenAplicaciones3'
import ImagenAplicaciones4 from '../components/imageComponents/imagenAplicaciones4'
import ImagenAplicaciones5 from '../components/imageComponents/imagenAplicaciones5'
import ImagenAplicaciones6 from '../components/imageComponents/imagenAplicaciones6'
import ImagenAplicaciones7 from '../components/imageComponents/imagenAplicaciones7'
import Gallery from '../components/imageComponents/galeria'

const IndexPage = () => (
  <Layout>
    <SEO title='Aplicaciones' />
    <Header siteTitle='APLICACIONES' />

    <Container>
      <div className='container'>
        <div className='row justify-content-md-center'>
          <div className='col-12 col-md-6 nopadding '>
           
            <SlideShowAplicaciones /> 
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ margin: '0.5em' }}>
              <h3 className={Estilos.title}>IDEAL PARA</h3>
              <ul className='aplicaciones tick'>
                <li>Hot Stamping</li>
                <li>Grabados</li>
                <li>Relieves</li>
                <li>Quemads</li>
                <li>Calcas</li>
                <li>Transfers</li>
                <li>Hologramas</li>
              </ul>
            </div>
          </div>
          <div className='col-12 conmargen nopadding' style={{ "align": "center" }}>
            <div style={{ "margin": "0.5em" }}>
              <h3 className={Estilos.username} style={{ "textAlign": "center", "margin": "1em" }}>
                MATERIALES QUE PUEDES IMPRIMIR
                </h3>
            </div>
          </div>


          <div className='col-12 col-md-6 conmargen'>
            <div style={{ margin: '1em' }}>
              <h3 className={Estilos.username}>GRÁFICAS</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Targetas</li>
                  <li>Calendarios</li>
                  <li>Portadas de libros</li>
                  <li>Agendas</li>
                  <li>Carpetas</li>
                  <li>Albumes</li>
                  <li>Bolsas de papel o plástico</li>
                  <li>Etiquetas</li>
                  <li>Cajas de cartón</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones2 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones3 />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ margin: '1em' }}>
              <h3 className={Estilos.username}>PUBLICIDAD</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Bolígrafos</li>
                  <li>Lapices de madera</li>
                  <li>Agendas</li>
                  <li>Llaveros de plástico</li>
                  <li>Agitadores</li>
                  <li>Fichas de plástico</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6'>
            <div style={{ margin: '1em' }}>
              <h3 className={Estilos.username}>INDÚSTRIA DEL PLÁSTICO</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Impresión y decoración de tapas</li>
                  <li>Envases</li>
                  <li>Perchas</li>
                  <li>Pequeñas cajas</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones4 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones5 />
          </div>
          <div className='col-12 col-md-6 '>
          <div style={{ margin: '1em' }}>
            <h3 className={Estilos.username}>INDÚSTRIA DE LA PIEL</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Cinturones</li>
                  <li>Carteras</li>
                  <li>Bolsos</li>
                  <li>Zapatos</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ margin: '1em' }}>
              <h3 className={Estilos.username}>INDÚSTRIA DE LA MADERA</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Perchas</li>
                  <li>Palets</li>
                  <li>Mangos de múltiples aplicaciones</li>
                </ul>
              </div>
            </div>
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones6 />
          </div>
          <div className='col-12 col-md-6 nopadding'>
            <ImagenAplicaciones7 />
          </div>
          <div className='col-12 col-md-6 conmargen'>
            <div style={{ margin: '1em' }}>
              <h3 className={Estilos.username}>INDÚSTRIA TEXTIL</h3>
              <div className='row'>
                <ul className='aplicaciones tick'>
                  <li>Bolsas de joyería</li>
                  <li>Etiquetas de composición</li>
                  <li>Cintas de regalo</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className='col-12  conmargen text-center'   style={{ padding: '2em' }} > <h3>MÁS</h3>  </div>
      </div>

      <Gallery />
    </Container>
    
  </Layout>
)

export default IndexPage
