import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import { useStaticQuery, graphql } from "gatsby"

const AppWrap = styled.div`
  margin: auto 0;
  flex: 0 1 250px;

  @media (max-width: 768px) {
    flex: 0 1 125px;
  }
`
const ImagenAplicaciones2 = () => {
  const data = useStaticQuery(graphql`
    query {
      file(name: { eq: "graficas" }, extension: { eq: "jpg" }) {
        childImageSharp {
          fluid(maxWidth: 500, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          },
          fixed(width: 500, height:400) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <AppWrap>
      <Img fluid={data.file.childImageSharp.fluid} alt="Mas ejemplos" />
      </AppWrap>
  )
}

export default ImagenAplicaciones2
