import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Gallery = () => {
  const data = useStaticQuery(graphql`
    query Images {
      images: allFile(
      sort: {fields: name, order: ASC},
      filter: {relativeDirectory:{eq:"gallery"}}){
        nodes {
          id
          childImageSharp {          
            fluid(maxWidth: 400, pngQuality: 100) {
            ...GatsbyImageSharpFluid
          },
          fixed(
              width:500, height:400
            ){
              ...GatsbyImageSharpFixed
              }
          }

        }
      }
    }
  `);

  return (
    <div class="row" style={{ width: '100%' }} >
        {data.images.nodes.map(image =>  {
          return  <div className='col-sm-12 col-md-6 '  style={{ padding: '1em' }}><Img key={image.id} fluid={image.childImageSharp.fluid}   backgroundColor={"#009900"} style={{"backgroundColor":"#FFA500"}}  fadeIn={true}/> </div>
        })}
    </div>

  )
}

export default Gallery;
