import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BackgroundImage from 'gatsby-background-image'
import Estilos from '../../components/mainContent/container.module.css'

const SlideShowAplicaciones = () => {
  const [index, setIndex] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      if (index === 22) { // total number of images minus 1
        setIndex(0);
      } else {
        setIndex(prev => prev + 1);
      }
    }, 3000); //duration
    return () => clearInterval(timer); //cleanup
  }, [index]); //compare

  // filter by sub-directory name slideshow
  const allImagesQuery = graphql`
    query {
        allFile(
          sort: {fields: name, order: ASC},
          filter: {relativeDirectory: {eq: "slideShowAplicaciones"}, 
            extension: {regex: "/(jpg)|(png)|(jpeg)/"}}) {
        totalCount
        edges {
            node {
            base
            childImageSharp {
              fixed(
                width:550, height:350,quality: 100,
              ){
                ...GatsbyImageSharpFixed_withWebp_noBase64
              }
              fluid(maxWidth: 550, pngQuality: 100){
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
            }
            }
        }
        }
    }
  `
  const {
    allFile: { edges: images }, //destructuring
  } = useStaticQuery(allImagesQuery)

  return (
    <>
      <BackgroundImage
        className={Estilos.masthead}
        // style={{width:"30%", height:"30"}}
        fluid={images[index].node.childImageSharp.fluid}
        // alt={images[index].node.base.split(".")[0]}
        fadeIn={false}
        onError={(err) => console.log(err) }
        backgroundColor={"#1d1d1d"} style={{ "backgroundColor": "#1d1d1d" }}
      >
      
      </BackgroundImage>
    </>
  )
}

export default SlideShowAplicaciones